import React from "react"
import {graphql} from "gatsby"
import Layout from '../components/layout'
import SEO from '../components/seo'
import Container from "../components/container";

const Template = ({
                      data, // this prop will be injected by the GraphQL query below.
                  }) => {
    const {markdownRemark} = data // data.markdownRemark holds our post data
    const {frontmatter, html} = markdownRemark
    return (
        <Layout>
            <SEO title={frontmatter.title} keywords={[`gatsby`, `application`, `react`]}/>
            <Container>
                {frontmatter.showTitle !== false ? <h1>{frontmatter.title}</h1> : null}
                <div dangerouslySetInnerHTML={{__html: html}}/>
            </Container>
        </Layout>
    )
}

export default Template

export const pageQuery = graphql`
  query($path: String!) {
        markdownRemark(frontmatter: {path: {eq: $path }}) {
        html
      frontmatter {
        path
        title
        showTitle
      }
    }
  }`